App.Modules = App.Modules || {};
App.Modules.MobileNavigation = function ($) {

  /*only difference from bulbrite-products is that the variables defined up top are not used. they don't work in WP for some reason. D:*/

  var $nav =  $('nav.primary'),
    $mobileSearchBox = $('.mobile-search-box'),
    $desktopSearchBox =  $('.js-search .text--search'),

    openNav = function(data) {
      $('nav.primary').fadeIn(250, function() {
        $('body').css('overflow', 'hidden');
      });
    },

    closeNav = function(data) {
      $('nav.primary').fadeOut(250, function() {
        $('body').css('overflow', '');
      });
    },

    forceShow = function(data)
    {
      $('nav.primary').css('display', 'block');
      $('body').css('overflow', '');
    },

    forceHide = function(data)
    {
      $('nav.primary').css('display', 'none');
      
      // prevent search box from being hidden if the user has clicked into search
      // keyboards on mobile were forcing the resize event to fire
      // http://stackoverflow.com/a/15168308/1843916

      if( ! $(document.activeElement).attr('type') === 'text' ) {
        $('.mobile-search-box').css('display', 'none');
        $('.js-search .text--search').css('display', 'none');
        $('.js-search .fa-close').css('display', 'none');
      }

      $('body').css('overflow', '');
    },

    toggleSearch = function(data)
    {
      if( $(window).width() >= 768 ) {
        $('.js-search .text--search').focus();
        $('.js-search .hide-1024').fadeOut(250, function() {
          
          if( $(window).width() >= 768 && $(window).width() <= 1024 ) {
           $('.js-search .search-wrap').addClass('js-active-search').animate({'right':'150px'});
          }
          if( $(window).width() >= 1024 ) {
           $('.js-search .search-wrap').addClass('js-active-search').animate({'right':'175px'});
          }

          $('.js-search .text--search').fadeIn();
          $('.js-search .fa-close').fadeIn();
        });
      } else {
        $('.mobile-search-box').slideToggle();
        $('.mobile-search-box').find('.text--search').focus();
      }
    },

    closeDesktopSearch = function(data)
    {
      $('.js-search .text--search').fadeOut(250, function() {
        $('.js-search .fa-close').fadeOut(100, function() {
          $('.js-search .search-wrap').animate({'right':'51px'}, function() {

            if( $(window).width() >= 1024 ) {
              $('.js-search .hide-1024').fadeIn(); 
            }

            $('.js-search .search-wrap').removeClass('js-active-search');
          });
        });
      });
    },

    menuResize = function(data)
    {
        if( $(window).width() >= 768 ) {
          forceShow(data);
        } else {
          forceHide(data);
        }

        if ( ! $('.js-search .search-wrap').hasClass('js-active-search') ) {
          if( $(window).width() <= 1024 ) {
            $('.js-search .hide-1024').hide(); 
          } else {
            $('.js-search .hide-1024').show();
          }
        }
    };

  return {
    init: function() { return this; },
    events: function() {
      Events.bind("click", ".js-mobile-menu-open").withoutBubble().to(openNav, this);
      Events.bind("click", ".js-mobile-menu-close").withoutBubble().to(closeNav, this);
      Events.bind("click", ".js-search .search-wrap").withoutBubble().to(toggleSearch, this);
      Events.bind("click", ".js-close-search").withoutBubble().to(closeDesktopSearch, this);
      Events.bind("resize").to(Utils.debounce(menuResize, 25), this);

      return this;
    }
  };
}(jQuery);
