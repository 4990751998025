App.Modules = App.Modules || {};
App.Modules.eisaChart = function () {

  // this thing changes the dropdown on the EISA charts
  // it sucks and it's very brittle/non-flexible
  // if they want to add more charts, gonna have to rewrite this

  var eisaDropdown = function(data) {
      // get the value of the dropdown the user touched
      eisa = jQuery('.active-chart .js-eisa-dropdown').val();
      
      // set the value for BOTH dropdowns to be the same
      // because we have two different charts...
      jQuery('.eisa-chart .js-eisa-dropdown').val(eisa);

      // make sure that the arrows are not disabled
      // if the chart is flipping to a different chart
      jQuery('.eisa-chart-wrapper .prev, .eisa-chart-wrapper .next').removeClass('disabled');
      
      //choose halogen
      if ( eisa === 'halogen' ) {
        jQuery('#halogen').addClass('active-chart');
        jQuery('#incandescent').removeClass('active-chart');
      }

      //choose incandescent
      if ( eisa === 'incandescent' ) {
        jQuery('#incandescent').addClass('active-chart');
        jQuery('#halogen').removeClass('active-chart');
      }
  };

  // add the "active-cell" class to the correct cells
  var eisaChartMobileClasses  = function(data) {
    jQuery('.eisa-chart td:nth-child(3)').addClass('active-cell');
  };

  // mobile switcher: next column of data
  var eisaMobileSwitchNext  = function(data) {
    currentCells = jQuery('.active-chart td.active-cell');

    // make sure it isn't the last column of cells: there's nothing "next" after this
    if ( ! currentCells.is(':last-child') ) {

      // make sure arrows are not disabled
      jQuery('.eisa-chart-wrapper .prev').removeClass('disabled');

      // add/remove classes to activate correct cells
      currentCells.removeClass('active-cell');
      currentCells.next('td').addClass('active-cell');
    } else {
      // otherwise, yeah, we're at the beginning: there is nothing more to show
      // so disable the arrow
      jQuery(data.elem).addClass('disabled');
    }
  };

  // mobile switcher: previous column of data
  var eisaMobileSwitchPrev  = function(data) {
    currentCells = jQuery('.active-chart td.active-cell');

    // make sure it isn't the "first" column of cells: there's nothing "next" after this
    // (NOTE: technically, the "first" column of cells is nth-child(3) because the first two
    // columns are the label, and the obsolete 100W incandescent to which we are comparing)
    if ( ! currentCells.is(':nth-child(3)') ) {
      // make sure arrows are not disabled
      jQuery('.eisa-chart-wrapper .next').removeClass('disabled');

      // add/remove classes to activate correct cells
      currentCells.removeClass('active-cell');
      currentCells.prev('td').addClass('active-cell');
    } else {
      // otherwise, yeah, we're at the beginning: there is nothing more to show
      // so disable the arrow
      jQuery( data.elem ).addClass('disabled');
    }
  };

  return {
    init: function() {
      eisaChartMobileClasses();
      return this;
  },
    events: function() {
      Events.bind("change", ".js-eisa-dropdown").to(eisaDropdown, this);
      Events.bind("click", ".eisa-chart-wrapper .next").to(eisaMobileSwitchNext, this);
      Events.bind("click", ".eisa-chart-wrapper .prev").to(eisaMobileSwitchPrev, this);
      return this;
    }
  };
}();
