App.Modules = App.Modules || {};
App.Modules.Recycling = function ($) {

  swapRecycling = function(data)
  {
    $('.js-recycling-image').attr( 'href', $(data.elem).attr('data-image') );
    $('.js-recycling-image').attr( 'src', $(data.elem).attr('data-image') );
  };

  return {
    init: function() { return this; },
    events: function() {
      Events.bind('mouseover', '.recycling-products tr').to(swapRecycling, this);
      return this;
    }
  };
}(jQuery);