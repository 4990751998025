App.Modules = App.Modules || {};
App.Modules.WhereToBuy = function () {

  // Design wanted the "get directions" link changed to show the distance instead but
  // Plugin has no options for this. Rewriting the PHP functions from the plugin and 
  // overwriting is much harder/way more lines than this silly JS hack.
  // Sorry in advance~

   var whereToBuyLink = function(data) {
      jQuery.each( jQuery('.radius-dis') , function(){
        jQuery( this ).hide();
        var distance = jQuery( this ).html();
        jQuery( this ).parent().children().children('a').html( distance + ' away' );
      });
   };

   return {
      init: function() {
        whereToBuyLink();
        return this;
    },
      events: function() {
         return this;
      }
   };
}();

