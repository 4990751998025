App.Modules = App.Modules || {};
App.Modules.Slickify = function () {

   // idk if "slick" used in function name/etc. will break stuff?
   var slickify = function(data) {
      jQuery('.products-slider-wrap').slick({
         slidesToShow: 1,
         slidesToScroll: 1,
         slide: '.products-slider-item',
         asNavFor: '.products-slider-menu',
         dots: false,
         arrows: true,

        prevArrow: '<a class="slick-arrow a-left control-c prev slick-prev"><img src="/wp-content/themes/_s/images/products-slider-arrow.svg"></a>',
        nextArrow: '<a class="slick-arrow a-right control-c next slick-next"><img src="/wp-content/themes/_s/images/products-slider-arrow.svg"></a>'
      });

      jQuery('.products-slider-menu').slick({
         slidesToShow: 9,
         slidesToScroll: 9,
         dots: false,
         arrows: false,
         slide: 'li',
         asNavFor: '.products-slider-wrap',
         focusOnSelect: true
      });
   };

   return {
      init: function() {
        slickify();
        return this;
    },
      events: function() {
         return this;
      }
   };
}();
