App.Modules = App.Modules || {};
App.Modules.ProductsMenu = function ($) {

  var $menu = $('.products-menu'),
    timeout,

    openMenu = function() {
      if( $(window).width() >= 768 ) {
        $('.products-menu').fadeIn();
        clearTimeout(timeout);
      }
    },

    closeMenu = function() {
      if( $(window).width() >= 768 ) {
        timeout = setTimeout(function () {
          $('.products-menu').fadeOut();
        }, 250);
      }
    },

    swapImage = function(data)
    {
      var idx = $(data.elem).attr('data-img');

      $('.products-menu-images img').removeClass('active').eq(idx).addClass('active');
    };

  return {
    init: function() { return this; },
    events: function() {
      Events.bind('mouseover', '.js-products').to(openMenu, this);
      Events.bind('mouseout', '.js-products').to(closeMenu, this);
      Events.bind('mouseover', '.products-menu [data-img]').to(swapImage, this);
      return this;
    }
  };
}(jQuery);