App.Modules = App.Modules || {};
App.Modules.Glossary = function () {

  // so the glossary plugin javascript was dumb... it exploded
  // when you tried to put two glossaries on the same page. 
  // clicks affected *BOTH* glossaries on the page. dumb. 
  // so i re-did the JS for it here.

  // ....... and it turns out design nixed the second of the
  // two glossaries so basically all of the below is to get 
  // correct classes for active/inactive items
  // why.jpg

   var glossary = function(data) {
        var lastPos;

        //always show the first alphabetic wrapper element in the glossary
        var firstEl = jQuery('.glossary-atoz-wrapper .glossary-atoz-wrapper ul:first-of-type');
        firstEl.removeClass('atozitems-off').addClass('atozitems-on');

        // Handle clicking
        jQuery('.glossary-atoz-wrapper ul.glossary-menu-atoz li a').click(function(){

          // remove the active item class from everything else in the glossary menu
          var wrapper = jQuery(this).parent(this).parent(this).children().children().removeClass('active-item');

          jQuery(this).removeClass('atozmenu-off').addClass('atozmenu-on active-item');
          var alpha = jQuery(this).parent().data('alpha');

          location.hash = alpha;

          var wrapper = jQuery(this).parent(this).parent(this).parent(this).children('.glossary-atoz-wrapper');

          wrapper.children('.glossary-atoz').removeClass('atozitems-on').addClass('atozitems-off');

          element = wrapper.children('.glossary-atoz-' + alpha);

          element.removeClass('atozitems-off').addClass('atozitems-on');
        });

   };

   return {
      init: function() {
        glossary();
        return this;
    },
      events: function() {
         return this;
      }
   };
}();
