App.Modules = App.Modules || {};
App.Modules.GoogleMaps = function () {

    var enableControls = function(data) {
      // allow pointer events on click
      jQuery('.google-maps-overlay iframe').removeClass('scrolloff');
    };

    return {
      init: function() {
        // set the pointer events to none on doc ready
        jQuery('.google-maps-overlay iframe').addClass('scrolloff'); 
        return this;
    },
    events: function() {
       Events.bind("click", ".google-maps-overlay").to(enableControls, this);
       return this;
    }
  };
}();

