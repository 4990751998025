App.Modules = App.Modules || {};
App.Modules.GoogleSiteSearch = function ($) {

  return {
    init: function() { 

	  (function() {
	    var cx = '011094292369755987049:wksolysaqcw';
	    var gcse = document.createElement('script');
	    gcse.type = 'text/javascript';
	    gcse.async = true;
	    gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
	    var s = document.getElementsByTagName('script')[0];
	    s.parentNode.insertBefore(gcse, s);
	  })();

  		return this; 
  	},
    events: function() {
      return this;
    }
  };
}(jQuery);