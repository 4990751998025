App.Modules = App.Modules || {};
App.Modules.newWindowPDF = function () {
    return {
      init: function() {
        jQuery('a[href$=".pdf"]').attr('target', '_blank');  
        return this;
    },
    events: function() {
      return this;
    }
  };
}();

