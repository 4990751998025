App.Modules = App.Modules || {};
App.Modules.ajaxPagination = function () {

  var page = 1;

   var loadPosts = function(data) {
      page++;
      data.event.preventDefault();
      jQuery('#loader').addClass('loading').html( '<img class="load" src="/wp-content/themes/_s/images/load.gif"> Loading Posts...' );

      jQuery.ajax({
          url: bulbritejs.ajaxurl,
          type: 'POST',
          data: {
            action: 'ajax_pagination',
            page: page,
            max_pages: bulbritejs.max_pages
          },
          success: function( result ) {

            if ( page <= bulbritejs.max_pages ) {
              jQuery('.js-load-more-posts').append( result.data );
              jQuery('#loader').removeClass('loading').html('');
            } else {
              jQuery('#loader').removeClass('loading').addClass('no-posts').html('No more posts to show.');
            }

          }
      });
   };

   return {
    init: function() {
      return this;
    },
      events: function() {
         if ( jQuery('body').hasClass('page-template-page-news') ) {
          Events.bind("click", "#js-get-next-posts").to(loadPosts, this);
         }
         
         return this;
      }
   };
}();
